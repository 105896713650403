import React from "react";
import {
  Signup,
  Login,
  Auth,
  ForgotPassword,
} from "components/Auth/AuthModule";
import Dashboard from "components/Dashboard";
import Agents from "components/admin/agent/agents";
import Reports from "components/admin/report/reports";
import Entity from "components/Entity";
import Intent from "components/Intent";
import Response from "components/Response";
import NoMatch from "components/NoMatch";
import UpdateProfile from "components/UpdateProfile";
import { AuthProvider } from "contexts/AuthContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Admin from "components/admin/admin";
import "global.css";
import Callbacks from "./admin/callback/callback";
import Configuration from "./admin/configuration/configuration";
import Optimization from "./admin/optimization/optimization";
import OptimizationReports from "./admin/optimizationReports/optimizationReports";
import OnlyDesktop from "./OnlyDesktop";
import IntegrationConfiguratorPage from "./admin/integration/integrationConfiguratorPage";
import IntegrationsPage from "./admin/integration/integrationsPage";
import Logging from "./admin/logging/logging";
import Gpt from "./admin/gpt/gpt";
import IntentStructure from "./admin/intentStructure/intentStructure";
import ChatMessages from "./admin/chatmessages/chatmessages";
import Datasource from "./admin/datasource/datasource";
import Charts from "./admin/charts/charts";
import Assistants from "./admin/assistants/assistants";

var isMobile = navigator.userAgent.match(
  /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
);

const App = () => {
  return (
    <>
      {isMobile ? (
        <OnlyDesktop />
      ) : (
        <Router>
          <AuthProvider>
            <Routes>
              <Route
                exact
                path=""
                element={
                  <Admin>
                    <Dashboard />
                  </Admin>
                }
              ></Route>
              <Route path="admin" element={<Admin />}>
                <Route
                  path="update-profile"
                  element={<UpdateProfile />}
                ></Route>
                <Route path="agents" element={<Agents />}></Route>
                <Route path="datasource" element={<Datasource />}></Route>
                <Route path="reports" element={<Reports />}></Route>
                <Route
                  path="integrationConfigurator"
                  element={<IntegrationConfiguratorPage />}
                ></Route>
                <Route
                  path="integrations"
                  element={<IntegrationsPage />}
                ></Route>
                <Route
                  path="optimizationReports"
                  element={<OptimizationReports />}
                ></Route>
                <Route path="optimization" element={<Optimization />}></Route>
                <Route path="logging" element={<Logging />}></Route>
                <Route path="configuration" element={<Configuration />}></Route>
                <Route
                  path="intentStructure"
                  element={<IntentStructure />}
                ></Route>
                <Route path="callbacks" element={<Callbacks />}></Route>
                {/* <Route path="chatmessages" element={<ChatMessages/>}></Route> */}
                <Route path="entity" element={<Entity />}></Route>
                <Route path="intent" element={<Intent />}></Route>
                <Route path="response" element={<Response />}></Route>
                <Route path="gpt" element={<Gpt />}></Route>
                <Route path="charts" element={<Charts />}></Route>
                <Route path="assistants" element={<Assistants />}></Route>
                <Route path="*" element={<NoMatch />} />
              </Route>
              <Route path="auth" element={<Auth />}>
                <Route path="signup" element={<Signup />} />
                <Route path="login" element={<Login />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="*" element={<NoMatch />} />
              </Route>
              <Route path="*" element={<NoMatch />} />
            </Routes>
          </AuthProvider>
        </Router>
      )}
    </>
  );
};

export default App;
