import React, { useEffect, useState } from "react";

import styles from "styles/admin/charts/charts.module.css";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

const Charts = () => {
  const [prompt, setPrompt] = useState();
  const [data, setData] = useState([
    { date: "03-08-2024", calls: 40 },
    { date: "04-08-2024", calls: 60 },
    { date: "05-08-2024", calls: 130 },
    { date: "06-08-2024", calls: 50 },
    { date: "07-08-2024", calls: 100 },
    { date: "08-08-2024", calls: 80 },
  ]);

  const updatePrompt = (e) => {
    setPrompt(e.target.value);
  };
  const testPrompt = async (e) => {
    var data = { question: prompt };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
      },
      body: JSON.stringify(data),
    };
    var result = await fetch(
      "https://localhost:7116/temp/getSqlData",
      requestOptions
    )
      .then((response) => response.json())
      .then((dat) => {
        console.log(dat);
        setData(dat);
        return dat;
      });
  };

  return (
    <div className={styles.gptContainer}>
      <div className={styles.template}>
        <h2>Charts:</h2>
        <textarea
          value={prompt}
          className={styles.textarea}
          rows={6}
          onChange={updatePrompt}
        ></textarea>
        <button onClick={testPrompt} className={styles.submit}>
          Test
        </button>

        <LineChart width={800} height={400} data={data}>
          <Line type="monotone" dataKey="calls" stroke="#8884d8" />
          <CartesianGrid stroke="#ccc" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
        </LineChart>
      </div>
    </div>
  );
};
export default Charts;
