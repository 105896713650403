import React, { useEffect, useState, useRef } from "react";
import styles from "styles/admin/agent/chatbox.module.css";
import { MdRefresh } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { FiSend } from "react-icons/fi";
import { useAgentContext } from "contexts/AgentContext";
import { assistantChat } from "services/chatSessionService";
import Loading from "components/global/loading";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useAuth } from "contexts/AuthContext";
import DeleteIcon from "@mui/icons-material/Delete";
import { useProgressIndicatorContext } from "contexts/ProgressIndicatorContext";

const AssistantChatbox = ({ assistant }) => {
  const { currentUser } = useAuth();
  const chatRef = useRef(null);
  const inputRef = useRef();
  const [sessionId, setSession] = useState("");
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isEnded, setIsEnded] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [historyMessages, setHistoryMessages] = useState([]);
  const {
    setIndicatorState,
    resetIndicatorState,
    setInfoMessage,
    loadingState,
  } = useProgressIndicatorContext();

  useEffect(() => {
    console.log(assistant);
    if (expanded) {
      startNewConversation();
    }
  }, [expanded, assistant]);

  useEffect(() => {
    if (
      messages.length > 0 &&
      messages[messages.length - 1].source == "human"
    ) {
      askNewResponse();
    } else if (
      messages.length > 0 &&
      messages[messages.length - 1].source == "ai" &&
      historyMessages?.length != 0
    ) {
      setMessages([...messages, historyMessages[0]]);

      let messArray = [...historyMessages];
      messArray.shift();
      setHistoryMessages(messArray);
    }
    if (messages.length > 0) {
      chatRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const toggleExpand = () => {
    setExpanded(!expanded);
    if (!expanded) {
      setMessages([]);
    }
  };

  const startNewConversation = async () => {
    setMessages([]);
    setLoading(true);
    setIsEnded(false);
    await doChatRequest("", null);
  };
  const setStartMessages = (data) => {
    var newMessages = [];
    if (data.messages != null) {
      data.messages.forEach((message) => {
        let o = JSON.parse(message).Object;

        if (o.length <= 1) {
          return;
        }
        newMessages.push({
          source: "ai",
          text: o,
        });
      });
      setMessages(newMessages);
    }
    setLoading(false);
    return newMessages;
  };

  const addMessages = (data) => {
    var newMessages = [];
    data.messages.forEach((message) => {
      const responseMessage = JSON.parse(message).Object;
      if (responseMessage.length <= 1) {
        return;
      }
      newMessages.push({
        source: "ai",
        text: responseMessage.replace(/\\n/g, "<br/>"),
      });
    });
    if (newMessages.length == 0) {
      return;
    }
    setMessages((prev) => [...prev, ...newMessages]);
  };

  const inputKeyDown = (e) => {
    if (e.keyCode == 13) {
      submitInput();
    }
  };

  const submitInput = async () => {
    if (isEnded) return;

    let message = inputRef.current.value;
    setMessages([
      ...messages,
      {
        source: "human",
        text: message,
      },
    ]);
    inputRef.current.value = "";
  };
  const askNewResponse = async () => {
    let message = messages[messages.length - 1].text;
    await doChatRequest(message, sessionId);
  };

  const doChatRequest = async (message, session) => {
    await testStream(assistant.id, session, message);
  };
  const handleText = () => (expanded ? "Close me" : "Try me");

  const testStream = async (assistantId, sessionId, message, attributes) => {
    let obj = {
      sessionId: sessionId,
      promptId: assistantId,
      source: "chat",
      message: message,
      getConversationParameters: true,
      attributes: attributes,
    };
    if (sessionId == null) {
      delete obj.sessionId;
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
      },
      body: JSON.stringify(obj),
    };
    setIsTyping(true);
    let response = await fetch(
      "https://api.dgtlassist.com/dgtlassist/gpt",
      requestOptions
    );

    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    if (response.status === 200) {
      let chunk;
      while (!(chunk = await reader.read()).done) {
        const textChunk = decoder.decode(chunk.value, { stream: true });
        const splitted = textChunk.split("data: ");
        splitted
          .filter((x) => x != "")
          .forEach((rawData) => {
            let data = getJsonPart(rawData);
            console.log(data);
            if (sessionId == "") {
              setSession(data.sessionId);
              setStartMessages(data);
            } else {
              setIsEnded(data.isEnded);
              setSession(data.sessionId);
              addMessages(data);

              if (data.event == "ASSISTANT_TRANSFER") {
                doChatRequest("", sessionId);
              }
            }
          });
      }
      setIsTyping(false);
    } else {
      setInfoMessage("Er ging iets fout bij het ophalen van een response");
    }
  };

  const getJsonPart = (eventString) => {
    try {
      const jsonString = eventString.substring(
        eventString.indexOf("{"),
        eventString.lastIndexOf("}") + 1
      );
      console.log(jsonString);
      return JSON.parse(jsonString);
    } catch (e) {
      console.error("Invalid JSON string", e);
      console.log(eventString);
      return null;
    }
  };
  return (
    <>
      {assistant && (
        <div data-expanded={expanded} className={styles.chatboxContainer}>
          <div onClick={toggleExpand} className={styles.handle}>
            <div className={styles.toggleText}>{handleText()}</div>
          </div>
          <div className={styles.chatboxHeader}>
            <div className={styles.headerTitle}>{assistant.assistantName}</div>

            {loading ? (
              <Loading />
            ) : (
              <DeleteIcon
                onClick={startNewConversation}
                className={styles.headerIcon}
              />
            )}

            <IoClose onClick={toggleExpand} className={styles.headerIcon} />
          </div>
          <div className={styles.chatboxContent}>
            {messages.map((message, index) => (
              <div
                key={index}
                className={styles.chatMessage}
                data-source={message.source}
              >
                {message.text.split("\n").map((x) => (
                  <div style={{ minHeight: "2rem" }}>{x}</div>
                ))}
              </div>
            ))}
            {isTyping && (
              <div className={styles.chatMessage} data-source="ai">
                is aan het typen...
              </div>
            )}
            <div ref={chatRef}></div>
          </div>
          <div className={styles.chatboxInput}>
            <input
              disabled={isEnded}
              onKeyDown={inputKeyDown}
              ref={inputRef}
              type="text"
            />
            <FiSend
              onClick={submitInput}
              className={`${styles.inputIcon} ${
                isEnded ? styles.disabledButton : null
              }`}
            />
          </div>
        </div>
      )}
    </>
  );
};
export default AssistantChatbox;
