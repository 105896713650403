import { db } from "../firebase.js";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  getDoc,
  where,
  query,
  setDoc,
  deleteDoc,
} from "firebase/firestore";

const getApiSquats = async (companyName) => {
  const reference = collection(db, "assistant_squats");
  const q = query(reference, where("customer", "==", companyName));

  return await getDocs(q).then((data) => {
    return data.docs.map((docSnap) => ({ ...docSnap.data(), id: docSnap.id }));
  });
};
const getApiSquatAssistants = async (squatId) => {
  const reference = collection(db, "assistant_configurations");
  const q = query(reference, where("squatId", "==", squatId));

  return await getDocs(q).then((data) => {
    return data.docs.map((docSnap) => ({ ...docSnap.data(), id: docSnap.id }));
  });
};

const updateApiAssistant = async (assistant) => {
  return await setDoc(
    doc(db, "assistant_configurations", assistant.id),
    assistant
  );
};

const deleteApiAssistant = async (id) => {
  const docRef = doc(db, "assistant_configurations", id);
  return await deleteDoc(docRef);
};
const deleteApiSquat = async (id) => {
  const docRef = doc(db, "assistant_squats", id);
  return await deleteDoc(docRef);
};

const updateApiSquat = async (squat) => {
  return await setDoc(doc(db, "assistant_squats", squat.id), squat);
};

const addNewApiSquat = async (data) => {
  return await setDoc(doc(db, "assistant_squats", data.id), data);
};
const addNewApiAssistant = async (data) => {
  return await setDoc(doc(db, "assistant_configurations", data.id), data);
};

const attachApiAssistant = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(data),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL +
      "/voximplant/attachVoximplantAzureAssistant",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};
export {
  getApiSquats,
  getApiSquatAssistants,
  updateApiAssistant,
  updateApiSquat,
  deleteApiAssistant,
  addNewApiSquat,
  addNewApiAssistant,
  deleteApiSquat,
  attachApiAssistant,
};
