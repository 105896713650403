import React, { useState, useMemo, useEffect } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { MdOutlineManageAccounts } from "react-icons/md";
import { SlArrowDown } from "react-icons/sl";
import { getAllIntegrations } from "../../../services/integrationService";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";

import styles from "styles/admin/integration/integrationsPage.module.css";
import {
  getApiCompanies,
  getApiCompany,
  updateCompany,
} from "services/companyService";

const IntegrationsPage = () => {
  const [selectedIntegration, setSelectedIntegration] = useState();
  const [integrationGroups, setIntegrationGroups] = useState();
  const [installedIntegrations, setInstalledIntegrations] = useState({});
  const [company, setCompany] = useState();
  const [companies, setCompanies] = useState([]);
  const { currentDbUser } = useAuth();
  const [searchQuery, setSearchQuery] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (!currentDbUser) return;

    const getCompanies = async () => {
      setIntegrationGroups(await getAllIntegrations());
      if (currentDbUser.isAdmin) {
        var retrievedCompanies = await getApiCompanies();
        setCompanies(retrievedCompanies);
        getAndSetCompanyFromLocalStorage(retrievedCompanies);
      } else {
        var retrievedCompanies = await getApiCompany(currentDbUser.companyId);
        setCompanies([...retrievedCompanies]);
        getAndSetCompanyFromLocalStorage([...retrievedCompanies]);
      }
    };
    getCompanies();
  }, [currentDbUser]);

  const getAndSetCompanyFromLocalStorage = (retrievedCompanies) => {
    const companyName = localStorage.getItem("selectedCompany");
    var selectCompany = retrievedCompanies.filter(
      (x) => x.name == companyName
    )[0];
    if (selectCompany != null) {
      setCompany(selectCompany);
    } else {
      setCompany(retrievedCompanies[0]);
    }
  };

  useEffect(() => {
    if (!selectedIntegration) return;

    var reqFields = {};
    Object.entries(selectedIntegration.requiredFields)
      .filter((x) => x[1].refreshHook == "")
      .map((entry) => {
        var [key, obj] = entry;
        if (company.secrets[key] != null) {
          reqFields[key] = company.secrets[key].value;
          //updateRequiredField(key, agent.secrets[key].value);
        }
      });
    setRequiredfields(reqFields);
    setSelectedIntegrationHooks(
      company.installedIntegrations[selectedIntegration.name] || []
    );
  }, [selectedIntegration]);

  const filteredCompanies = useMemo(() => {
    // Create copy of item list
    var updatedList = [...companies];
    // Include all elements which includes the search query
    return (updatedList = updatedList.filter((item) => {
      return item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
    }));
  }, [companies, searchQuery]);

  const [searchQueryIntegrations, setSearchQueryIntegrations] = useState("");
  const filteredIntegrations = useMemo(() => {
    if (company == null || integrationGroups == null) return;

    var updatedList = [...integrationGroups];

    // Include all elements which includes the search query
    return updatedList.filter((item) => {
      return (
        item?.name
          .toLowerCase()
          .indexOf(searchQueryIntegrations.toLowerCase()) !== -1
      );
    });
  }, [company, searchQueryIntegrations]);

  const openDropDownSearch = () => {
    setMenuOpen(!menuOpen);
  };
  const companySelected = (e) => {
    setCompany(e);
    localStorage.setItem("selectedCompany", e.name);
    setSearchQuery("");
    openDropDownSearch();
  };

  const [requiredfields, setRequiredfields] = useState({});
  const updateRequiredField = (key, value) => {
    var tempRequiredfields = { ...requiredfields };
    tempRequiredfields[key] = value;
    setRequiredfields(tempRequiredfields);
  };

  const requiredFieldsFilled = () => {
    if (selectedIntegrationHooks.length == 0) return false;

    var requiredLength = Object.entries(
      selectedIntegration.requiredFields
    ).filter((x) => x[1].refreshHook == "").length;
    var filled = Object.values(requiredfields).filter((x) => x != "").length;
    return filled == requiredLength;
  };

  const [selectedIntegrationHooks, setSelectedIntegrationHooks] = useState([]);
  const selectIntegrationHook = (name, checked) => {
    if (checked && selectedIntegrationHooks.indexOf(name) == -1) {
      setSelectedIntegrationHooks([...selectedIntegrationHooks, name]);
    } else if (selectedIntegrationHooks.indexOf(name) != -1) {
      setSelectedIntegrationHooks([
        ...selectedIntegrationHooks.filter((x) => x != name),
      ]);
    }
  };

  const flexContainer = {
    display: "flex",
    flexDirection: "row",
    marginLeft: "1rem",
  };

  const instalIntegration = () => {
    var newCompany = { ...company };
    var secretsObj = { ...newCompany.secrets };

    Object.entries(selectedIntegration.requiredFields).map((entry) => {
      var [key, obj] = entry;
      var value = requiredfields[key];
      var newSecret = {
        type: obj.type,
        name: obj.name,
        lastRefreshed: "",
        refreshTimeInMinutes: obj.refreshTimeInMinutes ?? "",
        value: value ?? "",
        refreshHook: obj.refreshHook,
      };
      secretsObj[key] = newSecret;
    });
    newCompany.secrets = secretsObj;
    newCompany.installedIntegrations[selectedIntegration.name] =
      selectedIntegrationHooks;

    updateCompany(newCompany).then((result) => {
      if (!result.isError) {
        setCompany(result.object);
      }
    });
  };

  const toggleSelectedIntegration = (integration) => {
    setSelectedIntegration(integration);
  };
  const integrationIsInstalled = () =>
    company.installedIntegrations
      ? Object.keys(company.installedIntegrations).indexOf(
          selectedIntegration.name
        ) != -1
      : false;

  return (
    <div className={`${styles.integrations}`}>
      <div className={`${styles.header} ${styles.widget}`}>
        <h1>Integrations</h1>
        <h3>Select integrations for your company</h3>
      </div>

      <div className={`${styles.agentSelection} ${styles.widget}`}>
        <div className={styles.optionsAgentSelection}>
          <div className={styles.optionsHeader}>
            <MdOutlineManageAccounts className={styles.optionsIcon} /> Select
            company
          </div>
          <div className={styles.dropdown}>
            <button onClick={openDropDownSearch} className={styles.dropbtn}>
              {company?.name}{" "}
              <SlArrowDown
                className={`${styles.dropdownIcon} ${
                  menuOpen ? styles.rotate : null
                }`}
              />
            </button>
            <div
              id={styles.myDropdown}
              className={`${styles["dropdown-content"]} ${
                menuOpen ? styles.show : null
              }`}
            >
              <input
                value={searchQuery}
                type="text"
                placeholder="Zoeken"
                id={styles.myInput}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              {filteredCompanies.map((s) => {
                return (
                  <span key={s.name} onClick={() => companySelected(s)}>
                    {s.name}
                  </span>
                );
              })}
              {filteredCompanies.length === 0 ? (
                <span className={styles.noResult}>Geen resultaten</span>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.integrationSelection} ${styles.widget}`}>
        {company != null && integrationGroups != null && (
          <>
            <div className={styles.row}>
              <input
                value={searchQueryIntegrations}
                placeholder="search"
                onChange={(e) => setSearchQueryIntegrations(e.target.value)}
                className={styles.inputSearch}
                type="text"
              />
            </div>
            <div className={`${styles.integrationSelectionContainer}`}>
              {filteredIntegrations?.map((x) => (
                <div
                  onClick={() => toggleSelectedIntegration(x)}
                  className={`${styles.integrationSelectionItem} ${
                    selectedIntegration == x ? styles.highlightIntegration : ""
                  }`}
                >
                  <img src={x.icon} />
                  <div className={styles.integrationName}>{x.name}</div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <div className={`${styles.integrationDetailContainer} ${styles.widget}`}>
        {company && selectedIntegration && (
          <>
            <div className={styles.integrationSummary}>
              <div className={styles.integrationSummaryName}>
                <h1>{selectedIntegration.name}</h1>
                {integrationIsInstalled() ? (
                  <>
                    <div className={styles.installed}>
                      integration is installed
                    </div>
                    <div
                      onClick={instalIntegration}
                      className={styles.installButton}
                    >
                      update integration
                    </div>
                  </>
                ) : (
                  <div
                    onClick={instalIntegration}
                    className={`${styles.installButton} ${
                      !requiredFieldsFilled() ? styles.btnDisabled : ""
                    }`}
                  >
                    instal integration
                  </div>
                )}
              </div>
              <div className={styles.integrationSummaryDescription}>
                {selectedIntegration.description}
              </div>
            </div>
            <div className={styles.integrationSummary}>
              {Object.entries(selectedIntegration.requiredFields)
                .filter((x) => x[1].refreshHook == "")
                .map((field) => (
                  <>
                    <div
                      className={
                        styles.integrationRequiredFieldsContainerHeader
                      }
                    >
                      <div className={styles.conditionRow}>
                        <div className={styles.requiredFieldLabel}>
                          {field[1].name}
                        </div>
                        <div className={styles.requiredFieldInput}>
                          <input
                            onChange={(e) =>
                              updateRequiredField(field[1].name, e.target.value)
                            }
                            type="text"
                            value={requiredfields[field[1].name] || ""}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ))}
            </div>
            <div className={styles.integrationSummary}>
              <h3>Select integrations:</h3>
              {/* {Object.entries(selectedIntegration.hooks).filter(x => !x[1].settings.isTokenRefreshIntegration).map(hook => ( */}
              {Object.entries(selectedIntegration.hooks)
                .filter((x) => !x[1].settings.isTokenRefreshIntegration)
                .map((hook) => (
                  <>
                    <div
                      className={
                        styles.integrationRequiredFieldsContainerHeader
                      }
                    >
                      <div className={styles.conditionRow}>
                        <FormGroup style={flexContainer}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) =>
                                  selectIntegrationHook(
                                    hook[1].name,
                                    e.target.checked
                                  )
                                }
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                                checked={
                                  selectedIntegrationHooks?.indexOf(
                                    hook[1].name
                                  ) > -1
                                }
                              />
                            }
                            label={
                              <Box component="div" fontSize={12}>
                                {hook[1].name}
                              </Box>
                            }
                          />
                        </FormGroup>
                        <div className={styles.row}>{hook[1].description}</div>
                      </div>
                    </div>
                  </>
                ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default IntegrationsPage;
